<template>
    <div style="margin: 10px 0;">
<!--        <div class="bonus_txt"><i class="fa fa-warning"></i> 1.35 이상(포함) 배팅만 폴더수로 인정됩니다</div>-->
        <div class="sports_bonus">
            <button class="bw3 bg01" :class="{'active':  selectedAway=== sportsConst.WAY_HOME}"
                    @click="clickBonus(game1,sportsConst.WAY_HOME,1.03)">(3) 폴더 이상 <span
                    class="" style="float: right;margin-right: 5px;">1.03</span>
            </button>
            <button class="bw3 bg02" :class="{'active':  selectedAway=== sportsConst.WAY_DRAW}"
                    @click="clickBonus(game1,sportsConst.WAY_DRAW,1.05)">(5) 폴더 이상
                <span class="" style="float: right;margin-right: 5px;">1.05</span>
            </button>
            <button class="bw3 bg03" :class="{'active':  selectedAway=== sportsConst.WAY_AWAY}"
                    @click="clickBonus(game1,sportsConst.WAY_AWAY,1.07)">(7) 폴더 이상
                <span class="" style="float: right;margin-right: 5px;">1.07</span>
            </button>
        </div>
    </div>

</template>

<script>
    import sportsConst from "../../common/sportsConst";
    import {
        RECEIVE_CHECKED_GAME_TYPE, RECEIVE_CHECKED_LEAUGE_DELALL,
        RECEIVE_DISPLAY_POSTION,
        RECEIVE_SPORTS_CART_ITEM,
        RECEIVE_SPORTS_CART_ITEM_DEL, RECEIVE_SPORTS_CART_ITEM_DEL_ALL
    } from "../../store/mutation-types";
    import {mapGetters} from "vuex";

    export default {
        name: "SportsBonusComp",
        data() {
            return {
                sportsConst,
                selectedGameId: 0,
                selectedAway: 0,
                bonusGameList: [],
                game1: {
                    "id": 9999999999999,
                    "leagueId": 354,
                    "homeTeamName": "[3폴더이상 보너스]",
                    "drawTeamName": "[5폴더이상 보너스]",
                    "awayTeamName": "[7폴더이상 보너스]",
                    "homeTeamOdds": 1.03,
                    "drawOdds": 1.05,
                    "awayTeamOdds": 1.07,
                    "handicapVal": 0,
                    "overunderVal": 0,
                    "startTime": "2099-01-01 00:00",
                    "gameResult": 0,
                    "groupCode": "0",
                    "displayPosition": 1,
                    "league": {
                        "leagueName": "보너스 이벤트",
                        "leagueKrname": "보너스 이벤트",
                        "imageUrl": "",
                        "gameTypeId": 49,
                        "popular": 0,
                        "autoable": 1,
                        "sort": 0,
                        "leagueColor": "#d1d1d1",
                        "areaName": "국제",
                        "extraodds": 0,
                        "extraodds1": 0,
                        "fhlowodds": 0,
                        "foulowodds": 0,
                        "hhlowodds": 0,
                        "houlowodds": 0,
                        "specextraodds1": 0,
                        "specextraodds2": 0,
                        "gcount": 0
                    },
                    "handicapRelation": false,
                    "overunderRelation": false,
                    "specialRelation": false,
                    "sysn": false,
                    "gc": 3,
                    "gcount": 0,
                    "isShow": true,
                    "selectWay": -1,
                    "selectOdd": 1,
                    "moreGames": [],
                    "handicapGames": [],
                    "overunderGames": [],
                    "showMoreGames": false,
                    "kindText": "보너스",
                    "showLeague": true,
                    "isbonus": sportsConst.YES,
                    "kind": sportsConst.GAME_KIND_1X2,
                },

            }
        },
        computed: {
            ...mapGetters({
                'cartItems': 'getSportsCartItems',
            }),
        },
        methods: {
            clickBonus(game, way, odds) {

                if (way == game.selectWay) {
                    this.cartItems.forEach((item) => {
                        if (item.isbonus === sportsConst.YES) {
                            this.$store.commit(RECEIVE_SPORTS_CART_ITEM_DEL, item)
                        }
                    })
                    game.selectOdd = 0
                    this.selectedAway = -1;
                } else {
                    this.selectedGameId = 0;
                    this.selectedAway = 0;
                    this.cartItems.forEach((item) => {
                        if (item.isbonus === sportsConst.YES) {
                            this.$store.commit(RECEIVE_SPORTS_CART_ITEM_DEL, item)
                        }
                    })
                    game.selectWay = way
                    game.selectOdd = odds
                    this.$store.commit(RECEIVE_SPORTS_CART_ITEM, game)
                    this.selectedGameId = game.id;
                    this.selectedAway = way;
                }


            },
            leftBarHandle() {
                this.$bus.$emit('rightBarHandle', false)
                this.$bus.$emit('showMobileTopMenu', false)
                this.$bus.$emit('leftBarHandle', true)
            },
            gameTypeChange(type) {
                this.$store.commit(RECEIVE_CHECKED_GAME_TYPE, type)
                this.$store.commit(RECEIVE_CHECKED_LEAUGE_DELALL)
                if (this.$route.name !== 'sports') {
                    this.$router.push({path: '/sports'})
                    return false;
                }
            },
        },
        created() {
            this.bonusGameList.push(this.game1)
            this.bonusGameList.push(this.game2)
            this.bonusGameList.push(this.game3)
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/sports.css");

    .sports_bonus {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        --margin-bottom: 5px;
    }

    .sports_bonus button {
        width: 33.2%;
        height: 32px;
        line-height: 32px;
        background: linear-gradient(180deg, var(--sprotsGameBg1), var(--sprotsGameBg2));
        border-radius: 0px;
        color: #e9e9e9;
        --border: 1px solid #ffffff;
    }

    .active {
        background: linear-gradient(180deg, var(--sprotsGameBgActicve1), var(--sprotsGameBgActicve2)) !important;
        color: #ffffff !important;
        font-weight: bold !important;
        animation: sansan 1500ms infinite;
        -webkit-animation: sansan 1500ms infinite;
    }

    .sortby {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .sortby .s1 {
        height: 28px;
        line-height: 28px;
        color: #fff9ff;
        width: 80%
    }

    .sortby .s2 {
        width: 10%;
        text-align: right;
    }

    .sortby .s3 {
        width: 10%;
        text-align: right;
        padding-right: 5px;
    }

    @media screen and (max-width: 1024px) {
        .sortby {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .sortby .s1 {
            width: 60% !important;
        }

        .sortby .s2 {
            width: 20%;
        }

        .sortby .s3 {
            width: 20%;
            padding-right: 5px;
        }
    }

    .bonus_txt {
        width: 100%;
        color: #fc9393;
        text-align: center;
        margin: 5px 0;
        line-height: 22px;
        animation: sansan 1500ms infinite;
        -webkit-animation: sansan 1500ms infinite;
    }
    .bg01{
        --background: #b58c4e;
    }
    .bg02{
        --background: #9a8b74;
    }
    .bg03{
        --background: #9e9e9e;
    }

    @keyframes sansan {
        from {
            opacity: 1.0;
        }
        70% {
            opacity: 0.93;
        }
        50% {
            opacity: 0.76;
        }
        to {
            opacity: 1.0;
        }
    }

    @-webkit-keyframes sansan {
        from {
            opacity: 1.0;
        }
        50% {
            opacity: 0.4;
        }
        to {
            opacity: 1.0;
        }
    }


</style>